<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form autocomplete="off" method="post" @submit.prevent="addOrUpdateGrant()">
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'grantTypeToken'"
            :value="grant.grantTypeToken"
            :options="grantTypeTokenOptions"
            v-on:changeValue="grant.grantTypeToken = $event"
            :title="$t('Grants.grantTypeSelect')"
            :imgName="'type.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="`mathematicalTypeToken`"
            :value="grant.mathematicalTypeToken"
            :options="mathematicalTypeTokenOptions"
            v-on:changeValue="grant.mathematicalTypeToken = $event"
            :title="$t('Grants.selectMathematicalMethod')"
            :imgName="'type.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'grantNameAr'"
            :value="grant.grantNameAr"
            v-on:changeValue="grant.grantNameAr = $event"
            :title="$t('Grants.nameAr')"
            :imgName="'grants.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'grantNameEn'"
            :value="grant.grantNameEn"
            v-on:changeValue="grant.grantNameEn = $event"
            :title="$t('Grants.nameEn')"
            :imgName="'grants.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'grantNameUnd'"
            :value="grant.grantNameUnd"
            v-on:changeValue="grant.grantNameUnd = $event"
            :title="$t('Grants.nameUnd')"
            :imgName="'grants.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'grantDescriptionAr'"
            :value="grant.grantDescriptionAr"
            v-on:changeValue="grant.grantDescriptionAr = $event"
            :title="$t('Grants.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'grantDescriptionEn'"
            :value="grant.grantDescriptionEn"
            v-on:changeValue="grant.grantDescriptionEn = $event"
            :title="$t('Grants.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'grantDescriptionUnd'"
            :value="grant.grantDescriptionUnd"
            v-on:changeValue="grant.grantDescriptionUnd = $event"
            :title="$t('Grants.descriptionUnd')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'grantNotes'"
            :value="grant.grantNotes"
            v-on:changeValue="grant.grantNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'Grants' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import { setDataMultiLang } from "@/utils/functions";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomSelectBox,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,

      grantTypeTokenOptions: [],
      mathematicalTypeTokenOptions: [],
    };
  },
  props: ["grant", "submitName"],
  methods: {
    setDataMultiLang,
    async getDialogOfGrantTypes() {
      this.isLoading = true;
      this.grantTypeTokenOptions = [];
      this.grantTypeTokenOptions.push({
        value: "",
        text: this.$t("Grants.grantTypeSelect"),
      });
      let itemsData = this.$store.getters.userData.constantLists.listGrantType;
      for (let item in itemsData) {
        this.grantTypeTokenOptions.push({
          value: itemsData[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            itemsData[item]["itemNameAr"],
            itemsData[item]["itemNameEn"]
          ),
        });
      }

      this.isLoading = false;
    },
    async getDialogOfMathematicalType() {
      this.isLoading = true;
      this.mathematicalTypeTokenOptions = [];
      this.mathematicalTypeTokenOptions.push({
        value: "",
        text: this.$t("effectTypes.selectMathematicalMethod"),
      });
      let deductions =
        this.$store.getters.userData.constantLists.listMathematicalType;
      for (let item in deductions) {
        this.mathematicalTypeTokenOptions.push({
          value: deductions[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            deductions[item]["itemNameAr"],
            deductions[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    addOrUpdateGrant() {
      this.$emit("addOrUpdateGrant");
    },
  },
  watch: {},
  created() {
    this.getDialogOfGrantTypes();
    this.getDialogOfMathematicalType();
  },
};
</script>
